import React from 'react';

const HeroToMe = () => (
    <div className="music-card">
        <span className="capo">Capo 2</span>
        <p>
            <strong className="intro-label">Intro:</strong> <span className="ind-chord">G</span>&nbsp;&nbsp;&nbsp;<span className="ind-chord">C</span>&nbsp;&nbsp;&nbsp;<span className="ind-chord">G</span>&nbsp;&nbsp;&nbsp;<span className="ind-chord">C</span>
        </p>
        <p>
            <strong>Verse 1:</strong>
            <span className="p-rel">Remember<span className="p-abs beginning">G</span></span> our <span className="p-rel">story<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Of<span className="p-abs beginning">D/F<sup>#</sup></span></span> pillow talks <span className="p-rel">through<span className="p-abs beginning">C</span></span> the <span className="p-rel">night.<span className="p-abs beginning">G</span></span><br /><br />
            <span className="p-rel">Adventures<span className="p-abs beginning">C</span></span> always <span className="p-rel">stirring<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            <span className="p-rel">Though<span className="p-abs beginning">G</span></span> we'd <span className="p-rel">rather<span className="p-abs beginning">C</span></span> stay <span className="p-rel">inside.<span className="p-abs beginning">F</span></span><br /><br />
            <span className="p-rel">Our<span className="p-abs beginning">G</span></span> laughs and <span className="p-rel">jokes<span className="p-abs beginning">C</span></span> and <span className="p-rel">scares<span className="p-abs beginning">D/F<sup>#</sup></span></span> we <span className="p-rel">had.<span className="p-abs beginning">C</span></span><br /><br />
            Of <span className="p-rel">trees<span className="p-abs beginning">G</span></span> <span className="p-rel">falling<span className="p-abs beginning">C</span></span> <span className="p-rel">down.<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            Our <span className="p-rel">basement<span className="p-abs beginning">G</span></span> <span className="p-rel">creaks<span className="p-abs beginning">C</span></span> and <span className="p-rel">haunts<span className="p-abs beginning">D/F<sup>#</sup></span></span> us <span className="p-rel">still<span className="p-abs beginning">C</span></span><br /><br />
            We'd <span className="p-rel">run<span className="p-abs beginning">F</span></span> without a <span className="p-rel">sound<span className="p-abs beginning">D/F<sup>#</sup></span></span>
        </p>
        <p>
            <strong>Chorus 1:</strong>
            Even <span className="p-rel">after<span className="p-abs beginning">G</span></span> all these <span className="p-rel">times<span className="p-abs beginning">Am7</span></span><br /><br />
            <span className="p-rel">Many<span className="p-abs beginning">G</span></span> years <span className="p-rel">we've<span className="p-abs beginning">Em</span></span> been <span className="p-rel">apart.<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Remembering<span className="p-abs beginning">G</span></span> the <span className="p-rel">times<span className="p-abs beginning">Am</span></span> we <span className="p-rel">had<span className="p-abs beginning">F</span></span><br /><br />
            <span className="p-rel">Grow<span className="p-abs beginning">G</span></span> fonder in my <span className="p-rel">heart.<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            Couldn't <span className="p-rel">picture<span className="p-abs beginning">G</span></span> life without <span className="p-rel">you,<span className="p-abs beginning">Am7</span></span><br /><br />
            Didn't <span className="p-rel">think<span className="p-abs beginning">G</span></span> this <span className="p-rel">could<span className="p-abs beginning">Em</span></span> <span className="p-rel">be:<span className="p-abs beginning">C</span></span><br /><br />
            A <span className="p-rel">brother<span className="p-abs beginning">G</span></span> and <span className="p-rel">friend<span className="p-abs beginning">Am</span></span> in <span className="p-rel">one<span className="p-abs beginning">F</span></span><br /><br />
            Or <span className="p-rel">a<span className="p-abs beginning">C</span></span> hero to <span className="p-rel">me.<span className="p-abs beginning">F</span></span>
        </p>
        <p>
            <strong>Verse 2:</strong>
            <span className="p-rel">Chimney<span className="p-abs beginning">G</span></span> <span className="p-rel">squirrels<span className="p-abs beginning">C</span></span> and <span className="p-rel">cut<span className="p-abs beginning">G</span></span> up <span className="p-rel">bears<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Most<span className="p-abs beginning">G</span></span> days <span className="p-rel">we<span className="p-abs beginning">C</span></span> got <span className="p-rel">along<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            <span className="p-rel">Lego<span className="p-abs beginning">G</span></span> <span className="p-rel">blocks,<span className="p-abs beginning">C</span></span> <span className="p-rel">cards,<span className="p-abs beginning">G</span></span> and <span className="p-rel">games<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Road<span className="p-abs beginning">G</span></span> tripping <span className="p-rel">with<span className="p-abs beginning">C</span></span> all our <span className="p-rel">songs<span className="p-abs beginning">F</span></span><br /><br />
            <span className="p-rel">Bee<span className="p-abs beginning">G</span></span> stings, <span className="p-rel">dogs<span className="p-abs beginning">C</span></span><br /><br />
            And <span className="p-rel">stretched<span className="p-abs beginning">G</span></span> out <span className="p-rel">shirts<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Tickle<span className="p-abs beginning">G</span></span> fights <span className="p-rel">that<span className="p-abs beginning">C</span></span> end in <span className="p-rel">line<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            Aah <span className="p-rel">the<span className="p-abs beginning">G</span></span> gross <span className="p-rel">beans<span className="p-abs beginning">C</span></span><br /><br />
            And <span className="p-rel">the<span className="p-abs beginning">G</span></span> smashed up <span className="p-rel">cup<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">You<span className="p-abs beginning">G</span></span> <span className="p-rel">left<span className="p-abs beginning">C</span></span> on the <span className="p-rel">side<span className="p-abs beginning">F</span></span><br /><br />
        </p>
        <p>
            <strong>Chorus 2:</strong>
            Even <span className="p-rel">after<span className="p-abs beginning">G</span></span> all these <span className="p-rel">times<span className="p-abs beginning">Am7</span></span><br /><br />
            <span className="p-rel">Many<span className="p-abs beginning">G</span></span> years <span className="p-rel">we've<span className="p-abs beginning">Em</span></span> been <span className="p-rel">apart.<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Remembering<span className="p-abs beginning">G</span></span> the <span className="p-rel">times<span className="p-abs beginning">Am</span></span> we <span className="p-rel">had<span className="p-abs beginning">F</span></span><br /><br />
            <span className="p-rel">Grow<span className="p-abs beginning">G</span></span> fonder in my <span className="p-rel">heart.<span className="p-abs beginning">D/F<sup>#</sup></span></span><br /><br />
            Take a <span className="p-rel">step<span className="p-abs beginning">G</span></span> back <span className="p-rel">relax.<span className="p-abs beginning">Am7</span></span><br /><br />
            Let <span className="p-rel">nostalga<span className="p-abs beginning">G</span></span> <span className="p-rel">take<span className="p-abs beginning">Em</span></span> you <span className="p-rel">away.<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Even<span className="p-abs beginning">G</span></span> on the <span className="p-rel">first<span className="p-abs beginning">C</span></span> of the <span className="p-rel">month.<span className="p-abs beginning">Am7</span></span><br /><br />
            <span className="p-rel">Have<span className="p-abs beginning">G</span></span> a <span className="p-rel">happy<span className="p-abs beginning">C</span></span> birth<span className="p-rel">day.<span className="p-abs beginning">G</span></span><br /><br />
        </p>
    </div>
);

export default HeroToMe;