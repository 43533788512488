import React from 'react';

const FragileHeart = () => (
    <div className="music-card">
        <span className="capo">Capo 3</span>
        <p>
            <strong>Verse 1:</strong>
            <span className="p-rel">Inside<span className="p-abs beginning">F</span></span> this <span className="p-rel">madness<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Is<span className="p-abs beginning">G</span></span> a fragile <span className="p-rel">heart<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">Composed<span className="p-abs beginning">F</span></span> on the <span className="p-rel">outside<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">But<span className="p-abs beginning">G</span></span> it's falling <span className="p-rel">apart<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Our<span className="p-abs beginning">F</span></span> lives seem so <span className="p-rel">simple<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Disney<span className="p-abs beginning">G</span></span> has <span className="p-rel">planned<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">Of<span className="p-abs beginning">F</span></span> life ever <span className="p-rel">afters<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Full<span className="p-abs beginning">G</span></span> of happen <span className="p-rel">stance<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Unravel<span className="p-abs beginning">F</span></span> the <span className="p-rel">truth<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Hard<span className="p-abs beginning">G</span></span> lessons I <span className="p-rel">learned<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">Life<span className="p-abs beginning">F</span></span> is a <span className="p-rel">journey<span className="p-abs beginning">C</span></span><br /><br />
            With <span className="p-rel">milestones<span className="p-abs beginning">G</span></span> we <span className="p-rel">earn<span className="p-abs beginning">C</span></span>
        </p>
        <p>
            <strong>Chorus:</strong>
            <span className="p-rel">We<span className="p-abs beginning">F</span></span> can each make a <span className="p-rel">choice,<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Though<span className="p-abs beginning">G</span></span> it's hard some<span className="p-rel">times<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">Progress<span className="p-abs beginning">F</span></span> a<span className="p-rel">lone,<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Will<span className="p-abs beginning">G</span></span> win every <span className="p-rel">time<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">As<span className="p-abs beginning">F</span></span> we take on each <span className="p-rel">moment,<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Each<span className="p-abs beginning">G</span></span> battle we <span className="p-rel">face<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">We<span className="p-abs beginning">F</span></span> can trust in His <span className="p-rel">hope,<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">His<span className="p-abs beginning">G</span></span> love, and His <span className="p-rel">grace<span className="p-abs beginning">C</span></span>
        </p>
        <p>
            <strong>Verse 2:</strong>
            <span className="p-rel">Life<span className="p-abs beginning">F</span></span> isn't <span className="p-rel">perfect<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Some<span className="p-abs beginning">G</span></span> things don't work <span className="p-rel">out<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">With<span className="p-abs beginning">F</span></span> our physical <span className="p-rel">limitations<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Or<span className="p-abs beginning">G</span></span> our mental <span className="p-rel">breakdowns<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Many<span className="p-abs beginning">F</span></span> tears will be <span className="p-rel">shed<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">On<span className="p-abs beginning">G</span></span> this difficult <span className="p-rel">course<span className="p-abs beginning">Am</span></span><br /><br />
            <span className="p-rel">Each<span className="p-abs beginning">F</span></span> hand has been <span className="p-rel">dealt<span className="p-abs beginning">C</span></span><br /><br />
            <span className="p-rel">Mine<span className="p-abs beginning">G</span></span> differs from <span className="p-rel">yours<span className="p-abs beginning">C</span></span>
        </p>
        <p>
            <strong>(Chorus)</strong>
        </p>
    </div>
);

export default FragileHeart;