import React from 'react';
import { Watermelon } from './loaders/Watermelon';
// import { Avocado } from './loaders/Avocado';

export const Loader = () => {
    // const loaders = [Watermelon, Avocado];
    // const randomLoader = loaders[Math.floor(Math.random() * loaders.length)];
    // return randomLoader();
    return <Watermelon />
}
