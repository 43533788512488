export const times = [
    { time: '0:01', number: 1 },
    { time: '0:02', number: 2 },
    { time: '0:03', number: 3 },
    { time: '0:04', number: 4 },
    { time: '0:05', number: 5 },
    { time: '0:06', number: 6 },
    { time: '0:07', number: 7 },
    { time: '0:08', number: 8 },
    { time: '0:09', number: 9 },
    { time: '0:10', number: 10 },
    { time: '0:11', number: 11 },
    { time: '0:12', number: 12 },
    { time: '0:13', number: 13 },
    { time: '0:14', number: 14 },
    { time: '0:15', number: 15 },
    { time: '0:16', number: 16 },
    { time: '0:17', number: 17 },
    { time: '0:18', number: 18 },
    { time: '0:19', number: 19 },
    { time: '0:20', number: 20 },
    { time: '0:21', number: 21 },
    { time: '0:22', number: 22 },
    { time: '0:23', number: 23 },
    { time: '0:24', number: 24 },
    { time: '0:25', number: 25 },
    { time: '0:26', number: 26 },
    { time: '0:27', number: 27 },
    { time: '0:28', number: 28 },
    { time: '0:29', number: 29 },
    { time: '0:30', number: 30 },
    { time: '0:31', number: 31 },
    { time: '0:32', number: 32 },
    { time: '0:33', number: 33 },
    { time: '0:34', number: 34 },
    { time: '0:35', number: 35 },
    { time: '0:36', number: 36 },
    { time: '0:37', number: 37 },
    { time: '0:38', number: 38 },
    { time: '0:39', number: 39 },
    { time: '0:40', number: 40 },
    { time: '0:41', number: 41 },
    { time: '0:42', number: 42 },
    { time: '0:43', number: 43 },
    { time: '0:44', number: 44 },
    { time: '0:45', number: 45 },
    { time: '0:46', number: 46 },
    { time: '0:47', number: 47 },
    { time: '0:48', number: 48 },
    { time: '0:49', number: 49 },
    { time: '0:50', number: 50 },
    { time: '0:51', number: 51 },
    { time: '0:52', number: 52 },
    { time: '0:53', number: 53 },
    { time: '0:54', number: 54 },
    { time: '0:55', number: 55 },
    { time: '0:56', number: 56 },
    { time: '0:57', number: 57 },
    { time: '0:58', number: 58 },
    { time: '0:59', number: 59 },
    { time: '1:00', number: 60 },
    { time: '1:01', number: 61 },
    { time: '1:02', number: 62 },
    { time: '1:03', number: 63 },
    { time: '1:04', number: 64 },
    { time: '1:05', number: 65 },
    { time: '1:06', number: 66 },
    { time: '1:07', number: 67 },
    { time: '1:08', number: 68 },
    { time: '1:09', number: 69 },
    { time: '1:10', number: 70 },
    { time: '1:11', number: 71 },
    { time: '1:12', number: 72 },
    { time: '1:13', number: 73 },
    { time: '1:14', number: 74 },
    { time: '1:15', number: 75 },
    { time: '1:16', number: 76 },
    { time: '1:17', number: 77 },
    { time: '1:18', number: 78 },
    { time: '1:19', number: 79 },
    { time: '1:20', number: 80 },
    { time: '1:21', number: 81 },
    { time: '1:22', number: 82 },
    { time: '1:23', number: 83 },
    { time: '1:24', number: 84 },
    { time: '1:25', number: 85 },
    { time: '1:26', number: 86 },
    { time: '1:27', number: 87 },
    { time: '1:28', number: 88 },
    { time: '1:29', number: 89 },
    { time: '1:30', number: 90 },
    { time: '1:31', number: 91 },
    { time: '1:32', number: 92 },
    { time: '1:33', number: 93 },
    { time: '1:34', number: 94 },
    { time: '1:35', number: 95 },
    { time: '1:36', number: 96 },
    { time: '1:37', number: 97 },
    { time: '1:38', number: 98 },
    { time: '1:39', number: 99 },
    { time: '1:40', number: 100 },
    { time: '1:41', number: 101 },
    { time: '1:42', number: 102 },
    { time: '1:43', number: 103 },
    { time: '1:44', number: 104 },
    { time: '1:45', number: 105 },
    { time: '1:46', number: 106 },
    { time: '1:47', number: 107 },
    { time: '1:48', number: 108 },
    { time: '1:49', number: 109 },
    { time: '1:50', number: 110 },
    { time: '1:51', number: 111 },
    { time: '1:52', number: 112 },
    { time: '1:53', number: 113 },
    { time: '1:54', number: 114 },
    { time: '1:55', number: 115 },
    { time: '1:56', number: 116 },
    { time: '1:57', number: 117 },
    { time: '1:58', number: 118 },
    { time: '1:59', number: 119 },
    { time: '2:00', number: 120 },
]